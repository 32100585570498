import { useDisplay } from "vuetify";

export const useMobile = () => {
  if (import.meta.server) {
    const { isMobile } = useDevice();
    return ref(isMobile);
  }

  const { mobile } = useDisplay();

  return mobile;
};
